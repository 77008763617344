var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "o-view o-view--center c-preview" }, [
    _c("h1", { staticClass: "c-preview__title" }, [
      _vm._v("Kies je audio en video instellingen voor je gesprek.")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-preview__video" }, [
      _c("video", { ref: "preview" })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-preview__options" }, [
      _c("ul", { staticClass: "list-inline" }, [
        _c("li", [
          _c("button", {
            staticClass: "c-btn c-btn--round c-btn--hollow icon-mic",
            class: { "c-btn--tertiary icon-mic-disabled": _vm.audio.off },
            attrs: { type: "button", disabled: _vm.audio.disabled },
            on: { click: _vm.toggleAudio }
          })
        ]),
        _vm._v(" "),
        _c("li", [
          _c("button", {
            staticClass: "c-btn c-btn--round c-btn--hollow icon-camera",
            class: { "c-btn--tertiary icon-camera-disabled": _vm.video.off },
            attrs: { type: "button", disabled: _vm.video.disabled },
            on: { click: _vm.toggleVideo }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-preview__action" }, [
      _c(
        "button",
        {
          staticClass: "c-btn c-btn--secondary",
          attrs: { type: "button" },
          on: { click: _vm.join }
        },
        [_vm._v("Nu deelnemen")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }