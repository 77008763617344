var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-actionbar" }, [
    _c("div", { staticClass: "c-actionbar__content" }, [
      _c("div", { staticClass: "c-actionbar__left" }, [
        _c(
          "button",
          {
            staticClass: "c-btn--unstyled",
            attrs: { type: "button" },
            on: { click: _vm.toggleFullScreen }
          },
          [
            _c("i", {
              class: [_vm.fullscreen ? "icon-shrink" : "icon-enlarge"]
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-actionbar__center" }, [
        _c("ul", { staticClass: "list-inline" }, [
          _c("li", [
            _c("button", {
              staticClass: "c-btn c-btn--round c-btn--hollow icon-mic",
              class: { "c-btn--tertiary icon-mic-disabled": _vm.audio.off },
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.toggle("audio")
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("li", [
            _c("button", {
              staticClass:
                "c-btn c-btn--round c-btn--hollow icon-phone c-btn--tertiary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("leave")
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("li", [
            _c("button", {
              staticClass: "c-btn c-btn--round c-btn--hollow icon-camera",
              class: { "c-btn--tertiary icon-camera-disabled": _vm.video.off },
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.toggle("video")
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.share
        ? _c("div", { staticClass: "c-actionbar__right" }, [
            !_vm.isSharing
              ? _c(
                  "button",
                  {
                    staticClass: "c-btn--unstyled",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("share")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-present" }),
                    _vm._v("Scherm delen")
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "c-btn--unstyled",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("endSharing")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-unpresent" }),
                    _vm._v("Delen afsluiten")
                  ]
                )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }