import Vue from 'vue';
import Vuex from 'vuex';

import local from './modules/local';
import context from './modules/context';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		local,
		context
	}
});

export default store;
