import SecureSocket from '@core/lib/Socket';

export default function (threshold) {
    const self = this;

    this.context = null;
    this.stream = null;
    this.mic = null;
    this.volume = 0.0;
    this.processor = null;

    this.start = 0;
    this.last = 0;
    this.speaking = false;
    self.threshold = ( threshold !== undefined ) ? threshold : 0.15;

    this.stop = function() {
        self.mic.disconnect();
        self.processor.disconnect();
    };

    this.connect = function (stream, onDetected, onVolume) {
        try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;

            if ( stream ) {
                self.context = new AudioContext()

                // Deprecated in near future. Should later on be replaced with AudioWorklet
                self.processor = self.context.createScriptProcessor(2048, 1, 1);
                self.processor.connect(self.context.destination);

                self.mic = self.context.createMediaStreamSource(stream);
                self.mic.connect(self.processor);

                self.stream = stream;

                self.processor.onaudioprocess = function (event) {
                    const input = event.inputBuffer.getChannelData(0);
                    let timestamp = Math.floor(Date.now() / 1000);
                    let sum = 0.0;
                    let clipcount = 0;

                    for ( let i = 0; i < input.length; ++i ) {
                        sum += input[i] * input[i];
                    }

                    self.volume = Math.sqrt(sum / input.length);

                    if ( onVolume && typeof onVolume === 'function' ) {
                        onVolume.apply(this, [self.volume]);
                    }

                    if ( self.volume >= self.threshold ) {
                        self.last = timestamp;

                        if ( self.start == 0 ) {
                            self.start = timestamp;

                        } else {

                            if ( timestamp - self.start > .3 ) {

                                if ( self.speaking == false ) {
                                    self.speaking = true;

                                    if ( onDetected && typeof onDetected === 'function' ) {
                                        onDetected.apply(this, [true]);
                                    }

                                } else {
                                    self.last = timestamp;
                                }
                            }
                        }
                    } else {

                        if ( (timestamp - self.last) > 1 ) {

                            if ( self.speaking == true ) {

                                if ( onDetected && typeof onDetected === 'function' ) {
                                    onDetected.apply(this, [false]);
                                }
                            }

                            self.start = 0;
                            self.speaking = false;
                        }
                    }
                };
            }
        } catch (e) {
            console.log(e);
        }

        return self;
    }
};
