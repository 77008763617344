<template>
    <div class="c-video" :class="{'c-video--portrait': portrait}">
        <div class="c-video__debug" v-if="debug">{{connection.uid}} - {{connection.audio_end}}</div>
        <video ref="video" v-show="!placeholder"></video>
        <div class="c-video__placeholder" v-if="placeholder">
            <div class="c-video__initials" v-if="connection.name">{{initials}}</div>
            <div class="c-video__icon" v-else>
                <img src="/res/assets/dist/img/svg/camera-disabled-white.svg" height="72"/>
            </div>
        </div>
        <div class="c-video__mute" v-if="!connection.audio"><i class="icon-mic-disabled"></i></div>
    </div>
</template>

<script>
export default {
    props: {
        connection: { required: true, type: Object}
    },
    data() {
        return {
            portrait: false,
            debug: false,
            placeholder: false
        }
    },
    computed: {
        initials() {
            let initials = '';

            if ( this.connection.name ){

                const parts = this.connection.name.replace(/[^a-zA-Z\ ]/g, '').split(' ');

                parts.forEach( (part) => {
                    initials += part.charAt(0)
                });
            }

            return initials;
        }
    },
    watch: {
        'connection.video'() {
            this.placeholder = !this.connection.video;
        }
    },
    mounted() {
        this.debug = ( window.location.host == 'talk.test');

        this.placeholder = !this.connection.video;

        this.$refs.video.srcObject = this.connection.stream;
        this.$refs.video.muted = true;

        this.$refs.video.onloadedmetadata = () => {
            this.$refs.video.play();

            const tracks = this.connection.stream.getVideoTracks();

            if ( tracks ) {
                const settings = tracks[0].getSettings();

                //this.portrait = ( settings.height > settings.width );
            }
        };
    }
}
</script>
