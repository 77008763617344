/**
 * State
 * @type {Object}
 */
const state = {
	room: null,
	name: null
}

/**
 * Getters
 * @type {Object}
 */
const getters = {
    getRoom( state ) {
        return state.room;
    },
	getName( state ) {
        return state.name;
    }
}

/**
 * Actions
 * @type {Object}
 */
const actions = {}

/**
 * Mutations
 * @type {Object}
 */
const mutations = {

	setRoom(state, room) {
        state.room = room;
	},
	setName( state, name ) {
        state.name = name;
    }
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
