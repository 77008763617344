import Utils from '@core/lib/Utils';
/**
* Cipher Utils
*
* Including:
* - RSA_OAEP
* - AES256_CBC
* - RSA_OAEP_AES256_CBC (end2end)
*
* @author Peter Oosterwijk
* @since 06/02/2020
**/
/**
* Cipher Utils
*
* Including:
* - RSA_OAEP
* - AES256_CBC
* - RSA_OAEP_AES256_CBC (end2end)
*
* @author Peter Oosterwijk
* @since 06/02/2020
**/

var window = ( window === undefined ) ? self : window;

export const RSA_OAEP = new (function () {

    var decodeKey = function (pem) {
        var lines = pem.split('\n');
        var encoded = '';

        for ( var i = 0; i < lines.length; i++ ) {

            if ( lines[i].trim().length > 0 && lines[i].indexOf('-BEGIN ') < 0 && lines[i].indexOf('-END ') <  0) {
                encoded += lines[i].trim();
            }
        }

        var byteStr = atob(encoded);
        var bytes = new Uint8Array(byteStr.length);

        for ( var i = 0; i < byteStr.length; i++ ) {
            bytes[i] = byteStr.charCodeAt(i);
        }

        return bytes;
    };

    this.genkeys = function (bitsize) {
        return new Promise((resolve, reject) => {
            bitsize = ( bitsize === undefined ) ? 2048 : bitsize;

            window.crypto.subtle.generateKey(
                {
                    name: "RSA-OAEP",
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: {
                        name: "SHA-1"
                    },
                },
                true,
                ["encrypt", "decrypt"]
            )
            .then(key => {
                resolve({
                    'public': key.publicKey,
                    'private': key.privateKey
                });
            })
            .catch(reject);
        });
    };

    this.importPrivateKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.importKey(
                "jwk",
                key,
                {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: {name: "SHA-256"}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                },
                true,
                ["decrypt"]
            )
            .then(key => {
                resolve(key);
            })
            .catch(reject);
        });
    };

    this.importPublicKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.importKey(
                "jwk",
                key,
                {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: {name: "SHA-256"}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                },
                true,
                ["encrypt"]
            )
            .then(key => {
                resolve(key);
            })
            .catch(reject);
        });
    };

    this.exportKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.exportKey(
                "jwk",
                key
            )
            .then(key => {
                resolve(key);
            })
            .catch(reject);
        });
    };

    this.exportPublicKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.exportKey(
                "spki",
                key
            )
            .then(key => {
                const exportedAsString = Utils.ab2str(key);
                const exportedAsBase64 = window.btoa(exportedAsString);
                const pem = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;

                resolve(pem);
            })
            .catch(reject);
        });
    };

    this.exportPrivateKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.exportKey(
                "pkcs8",
                key
            )
            .then(key => {
                const exportedAsString = Utils.ab2str(key);
                const exportedAsBase64 = window.btoa(exportedAsString);
                const pem = `-----BEGIN PRIVATE KEY-----\n${exportedAsBase64}\n-----END PRIVATE KEY-----`;

                resolve(pem);
            })
            .catch(reject);
        });
    };

    this.importPublicKeyPEM = function (key) {
        return new Promise((resolve, reject) => {
            var decoded = decodeKey(key);

            if ( decoded ) {
                window.crypto.subtle.importKey(
                    "spki",
                    decoded,
                    {
                        'name': 'RSA-OAEP',
                        'hash': {'name': 'SHA-1'}
                    },
                    true,
                    ["encrypt"]
                ).then(resolve).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.importPrivateKeyPEM = function (key) {
        return new Promise((resolve, reject) => {
            var decoded = decodeKey(key);

            if ( decoded ) {
                window.crypto.subtle.importKey(
                    "pkcs8",
                    decoded,
                    {
                        'name': 'RSA-OAEP',
                        'hash': {'name': 'SHA-256'},
                        modulusLength: 2048,
                        publicExponent: new Uint8Array([1, 0, 1])
                    },
                    true,
                    ["encrypt", "decrypt"]
                ).then(resolve).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.encrypt = function (data, publicKey) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.encrypt(
                {
                    'name': 'RSA-OAEP',
                },
                publicKey,
                ( data instanceof Uint8Array ) ? data : Utils.strToBytes(data)
            )
            .then(encrypted => {
                resolve(new Uint8Array(encrypted));
            })
            .catch(reject);
        });
    };

    this.decrypt = function (encrypted, privateKey) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.decrypt(
                {
                    'name': 'RSA-OAEP',
                },
                privateKey,
                ( encrypted instanceof Uint8Array ) ? encrypted : Utils.strToBytes(encrypted)
            )
            .then(decrypted => {
                resolve(new Uint8Array(decrypted));
            })
            .catch(reject);
        });
    };

    return this;
})();


export const AES256_CBC = new (function () {

    this.genkeys = function () {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.generateKey(
                {
                    name: "AES-CBC",
                    length: 256,
                },
                true,
                ["encrypt", "decrypt"]
            )
            .then(key => {
                const keys = {
                    'iv': window.crypto.getRandomValues(new Uint8Array(16)),
                    'key': key
                };

                resolve(keys);

            }).catch(reject);
        });
    };

    this.exportKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.exportKey(
                'raw',
                key
            )
            .then(key => {
                resolve(new Uint8Array(key));
            })
            .catch(reject);
        });
    };

    this.importKey = function (key) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.importKey(
                "raw",
                key,
                {
                    name: "AES-CBC",
                },
                true,
                ["encrypt", "decrypt"]
            )
            .then(key => {
                resolve(key);
            })
            .catch(reject);
        });
    };

    this.encrypt = function (data, key, iv) {
        return new Promise((resolve, reject) => {

            if ( data && key && iv ) {

                if ( typeof data == "string" ) {
                    data = new TextEncoder('utf-8').encode(data);
                }

                window.crypto.subtle.encrypt(
                    {
                        name: "AES-CBC",
                        iv: iv,
                    },
                    key,
                    data
                )
                .then(encrypted => {
                    resolve(new Uint8Array(encrypted));
                })
                .catch(reject);

            } else {
                reject();
            }
        });
    };

    this.decrypt = function (encrypted, key, iv) {
        return new Promise((resolve, reject) => {

            if ( encrypted && key && iv ) {
                window.crypto.subtle.decrypt(
                    {
                        name: "AES-CBC",
                        iv: iv,
                    },
                    key,
                    encrypted
                )
                .then(decrypted => {
                    resolve(new Uint8Array(decrypted));
                })
                .catch(reject);

            } else {
                reject();
            }
        });
    };

    return this;
})();


export const RSA_OAEP_AES256_CBC = new (function () {

    this.encrypt = function (data, public_key) {
        return new Promise((resolve, reject) => {

            if ( data && public_key ) {

                if ( data instanceof String ) {
                    data = new TextEncoder().encode(data);
                }

                AES256_CBC.genkeys().then(AESKeys => {

                    AES256_CBC.encrypt(data, AESKeys.key, AESKeys.iv).then(encrypted => {

                        AES256_CBC.exportKey(AESKeys.key).then(key => {

                            RSA_OAEP.encrypt(key, public_key).then(signature => {
                                var dataPackage = new Uint8Array(4 + 256 + 16 + encrypted.length);

                                dataPackage.set(new TextEncoder().encode(public_key.algorithm.modulusLength));
                                dataPackage.set(signature, 4);
                                dataPackage.set(AESKeys.iv, (4 + signature.length));
                                dataPackage.set(encrypted, (4 + signature.length + AESKeys.iv.length));

                                resolve(dataPackage)
                            });
                        }).catch(reject);
                    }).catch(reject);
                }).catch(reject);
            } else {
                reject();
            }
        });
    };

    this.decrypt = function (encrypted, private_key) {
        return new Promise((resolve, reject) => {

            if ( encrypted && private_key ) {

                if ( encrypted instanceof String ) {
                    encrypted = Utils.atob(encrypted);
                }

                if ( encrypted.length > 272 ) {
                    var bitsize = new Uint8Array(encrypted.buffer, 0, 4),
                        signature = new Uint8Array(encrypted.buffer, 4, 256),
                        iv = new Uint8Array(encrypted.buffer, 260, 16),
                        encrypted_data = new Uint8Array(encrypted.buffer, 276);

                    RSA_OAEP.decrypt(signature, private_key).then(raw_key => {

                        AES256_CBC.importKey(raw_key).then(key => {

                            AES256_CBC.decrypt(encrypted_data, key, iv).then(decrypted => {
                                resolve(decrypted);
                            }).catch(reject);
                        });
                    }).catch(reject);

                } else {
                    reject('No valid data');
                }
            } else {
                reject();
            }
        });
    };

    return this;
})();

export default {
    RSA_OAEP_AES256_CBC,
    RSA_OAEP,
    AES256_CBC
};
