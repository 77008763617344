/**
 * State
 * @type {Object}
 */
const state = {
	stream: null,
    audio: {
        off: true
    },
    video: {
        off: true
    }
}

/**
 * Getters
 * @type {Object}
 */
const getters = {

    getStream( {state} ) {
        return state.stream;
    }
}

/**
 * Actions
 * @type {Object}
 */
const actions = {

    addStream({ commit }, stream) {
        commit('setStream', stream);
    },

    turnOffAudio({ commit }) {
        commit('toggle', { key: 'audio', boolean: false });
    },

    turnOnAudio({ commit }) {
        commit('toggle', { key: 'audio', boolean: true });
    },

    turnOffVideo({ commit }) {
        commit('toggle', { key: 'video', boolean: false });
    },

    turnOnVideo({ commit }) {
        commit('toggle', { key: 'video', boolean: true });
    },

    toggleVideo({ commit }, boolean) {
        commit('toggle', { key: 'video', boolean });
    },

    toggleAudio({ commit }, boolean) {
        commit('toggle', { key: 'audio', boolean });
    },
}

/**
 * Mutations
 * @type {Object}
 */
const mutations = {

	setStream(state, stream) {
        state.stream = stream;
	},

    toggle(state, { key, boolean }) {
        state[key].off = boolean;
    }
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
