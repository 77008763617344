var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [_vm._m(0), _vm._v(" "), _c("router-view")], 1)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-logo" }, [
      _c("img", { attrs: { src: "/res/assets/dist/img/svg/logo.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }