import { RSA_OAEP, RSA_OAEP_AES256_CBC } from '@core/lib/Cipher';
import SignalingChannel from '@core/lib/Signaling';
import AudioDetection from '@core/lib/AudioDetection';
import Utils from '@core/lib/Utils';
// import Settings from '@app/settings';


// NOT IMPLEMENTED YET - UNDER CONTRUCTION
const RTCEncryptedDataChannel = function (conn, options) {
    var self = this;

    this.conn = conn;
    this.sendChannel = null;
    this.receiveChannel = null;

    this.options = Object.assign({
        channelName: 'data-channel'
    }, options);

    this.onOpen = function (event) {

    };

    this.onClose = function (event) {

    };

    this.onMessage = function (event) {

    };

    this.create = function () {

        if ( this.conn ) {
            self.sendChannel = conn.createDataChannel(self.options.channelName, {ordered: true});

            conn.createDataChannel = function (event) {

                if ( event.channel ) {
                    self.receiveChannel = event.channel;

                    self.receiveChannel.onopen = self.onOpen;
                    self.receiveChannel.onclose = self.onClose;
                    self.receiveChannel.onmessage = self.onMessage;
                }
            };
        }
    };

    return this;
};

export const RTCConnection = function (context, uid, pubkey, options) {
    var self = this,
        conn = null,
        local,
        remote,
        worker = new Worker('/res/assets/dist/js/encode.worker.js');
        // worker = new Worker(Settings.worker);

    this.uid = uid;
    this.pubkey = pubkey;
    this.privkey = context.channel.getKeys().private;
    this.context = context;
    this.remoteStream = null;
    this.senderAudio = null;
    this.senderVideo = null;
    this.receiverVideo = null;
    this.receiverAudio = null;
    this.dataChannel = null;
    this.audioDetection = null;
    this.audioActive = false;
    this.audioStarted = 0;
    this.audioEnded = 0;
    this.audioPlayer = null;
    this.encoded = false;

    this.options = {...{

        encodeMedia: true,

        // ICE (TURN) servers
        iceServers: context.iceServers,

        // Offer options
        offerOptions: {
            offerToReceiveAudio: true,
            offerToReceiveVideo: true
        },

        // Peer connection callbacks
        onAudioDetected: function (event) {},
        onAudioVolume: function (event) {},
        onMediaStream: function (event) {},
        onClose: function (event) {},
        onIceConnectionStateChange: function (event) {}
    }, ...options};

    this.sendStartVideoBroadcast = function () {

        // Will be replace with WebRTC datachannel
        context.channel.video(uid, true).then().catch(e => {

            if ( self.context.options.debug && typeof self.context.options.debug === 'function' ) {
                self.context.options.debug.apply(self, [e]);
            }
        });
    };

    this.sendStopVideoBroadcast = function () {

        // Will be replace with WebRTC datachannel
        context.channel.video(uid, false).then().catch(e => {

            if ( self.context.options.debug && typeof self.context.options.debug === 'function' ) {
                self.context.options.debug.apply(self, [e]);
            }
        });
    };

    this.stopVideoBroadcast = function () {

        if ( self.senderVideo ) {
            self.senderVideo.replaceTrack(context.emptyVideoStream.getVideoTracks()[0]);
        }
    };

    this.startVideoBroadcast = function () {

        if ( self.senderVideo ) {

            if ( context.screenStream !== null ) {
                self.senderVideo.replaceTrack(context.screenStream.getVideoTracks()[0]);

            } else {
                self.senderVideo.replaceTrack(context.localStream.getVideoTracks()[0]);
            }
        }
    };

    this.create = function () {
        return new Promise((resolve, reject) => {

            if ( context.localStream ) {
                conn = new RTCPeerConnection({iceServers: self.options.iceServers, encodedInsertableStreams: self.options.encodeMedia});

                if ( conn ) {

                    RSA_OAEP.importPublicKeyPEM(pubkey).then(result => {
                        pubkey = result;
                        this.pubkey = pubkey;

                        if ( result ) {
                            conn.addEventListener('icecandidate', self.onIceCandidate);
                            conn.addEventListener('iceconnectionstatechange', self.onIceConnectionStateChange);
                            conn.addEventListener('track', self.onRemoteStream);

                            if ( self.options.encodeMedia === true ) {
                                // Send RSA-keys to worker
                                worker.postMessage({
                                    operation: 'setKeys',
                                    keys: {
                                        public: self.pubkey, // Remote public key
                                        private: self.privkey // Local private key
                                    }
                                });
                            }

                            // Create sender tracks
                            for ( var track of context.localStream.getTracks() ) {

                                if ( track.kind == "video" ) {
                                    self.senderVideo = conn.addTrack(context.emptyVideoStream.getVideoTracks()[0], context.localStream);

                                } else if ( track.kind == "audio" ) {
                                    self.senderAudio = conn.addTrack(track, context.localStream);
                                }
                            }

                            if ( self.options.encodeMedia === true ) {
                                console.log("Create encoded streams");
                                self.createEncodedMediaStreams();
                            }

                            resolve(self);

                        } else {
                            reject("No public key");
                        }
                    });

                } else {
                    reject();
                }

            } else {
                reject("No stream");
            }
        });
    };

    this.createEncodedMediaStreams = function () {
        console.log("Encoding media");

        // Encode sender video
        if ( self.senderVideo ) {

            if ( self.options.encodeMedia === true ) {
                var senderVideoStreams = self.senderVideo.createEncodedStreams();
                
                worker.postMessage({
                    operation: 'encode',
                    readableStream: senderVideoStreams.readable,
                    writableStream: senderVideoStreams.writable,
                }, [senderVideoStreams.readable, senderVideoStreams.writable]);
            }
        }

        // Encode sender audio
        if ( self.senderAudio ) {

            if ( self.options.encodeMedia === true ) {
                var senderAudioStreams = self.senderAudio.createEncodedStreams();

                worker.postMessage({
                    operation: 'encode',
                    readableStream: senderAudioStreams.readable,
                    writableStream: senderAudioStreams.writable,
                }, [senderAudioStreams.readable, senderAudioStreams.writable]);
            }
        }
    };

    this.changeVideoTrack = function (track) {

        if ( self.senderVideo ) {
            self.senderVideo.replaceTrack(track);
        }
    };

    this.encrypt = function (data) {
        return new Promise((resolve, reject) => {

            if ( data ) {

                RSA_OAEP_AES256_CBC.encrypt(data, pubkey).then(encrypted => {
                    resolve(Utils.btoa(encrypted));
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.decrypt = function (data) {
        return new Promise((resolve, reject) => {

            if ( data ) {

                RSA_OAEP_AES256_CBC.decrypt(Utils.atob(data), context.channel.getKeys().private).then(decrypted => {
                    resolve(new TextDecoder().decode(decrypted));
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.onRemoteStream = function (event) {

        if ( event ) {

            if ( event.track.kind == "video" ) {
                self.receiverVideo = event.receiver;

                // Decode receiver video
                if ( self.receiverVideo ) {

                    if ( self.options.encodeMedia === true ) {
                        var receiverVideoStreams = self.receiverVideo.createEncodedStreams();

                        worker.postMessage({
                            operation: 'decode',
                            readableStream: receiverVideoStreams.readable,
                            writableStream: receiverVideoStreams.writable,
                        }, [receiverVideoStreams.readable, receiverVideoStreams.writable]);
                    }
                }

            } else if ( event.track.kind == "audio" ) {
                self.receiverAudio = event.receiver;

                // Decode receiver audio
                if ( self.receiverAudio ) {

                    if ( self.options.encodeMedia === true ) {
                        var receiverAudioStreams = self.receiverAudio.createEncodedStreams();

                        worker.postMessage({
                            operation: 'decode',
                            readableStream: receiverAudioStreams.readable,
                            writableStream: receiverAudioStreams.writable,
                        }, [receiverAudioStreams.readable, receiverAudioStreams.writable]);
                    }
                }

                // Create audio player for remote audio
                self.audioPlayer = new Audio();
                self.audioPlayer.srcObject = event.streams[0]
                self.audioPlayer.play()

                self.audioDetection = new AudioDetection(context.options.audioDetectionThreshold).connect(
                    event.streams[0],
                    self.onAudioDetection,
                    self.options.onAudioVolume
                );
            }

            if ( self.options.onMediaStream && typeof self.options.onMediaStream === 'function' ) {
                self.options.onMediaStream.apply(self, [event]);
            }
        }
    };

    this.onAudioDetection = function (active) {

        if ( active === true ) {
            self.audioActive = true;
            self.audioStarted = Math.floor(Date.now() / 1000);

        } else {
            self.audioActive = false;
            self.audioEnded = Math.floor(Date.now() / 1000);
        }

        if ( self.options.onAudioDetected && typeof self.options.onAudioDetected === 'function' ) {
            self.options.onAudioDetected.apply(self, [{active: active}]);
        }
    };

    this.onIceCandidate = function (event) {
        self.encrypt(JSON.stringify(event.candidate)).then(encrypted => {

            context.channel.ice(uid, encrypted).then().catch(e => {

                if ( self.context.options.debug && typeof self.context.options.debug === 'function' ) {
                    self.context.options.debug.apply(self, [e]);
                }

            }).catch(e => {

                if ( self.context.options.debug && typeof self.context.options.debug === 'function' ) {
                    self.context.options.debug.apply(self, [e]);
                }
            });

        }).catch(e => {

            if ( self.context.options.debug && typeof self.context.options.debug === 'function' ) {
                self.context.options.debug.apply(self, [e]);
            }
        });
    };

    this.onIceConnectionStateChange = function (event) {

        if ( self.options.onIceConnectionStateChange && typeof self.options.onIceConnectionStateChange === 'function' ) {
            self.options.onIceConnectionStateChange.apply(self, [event]);
        }
    };

    this.close = function (event) {
        self.audioDetection.stop();
        self.audioPlayer.pause();
        self.audioPlayer.currentTime = 0;
        self.audioPlayer = null;

        if ( self.options.onClose && typeof self.options.onClose === 'function' ) {
            self.options.onClose.apply(self, [event]);
        }
    };


    this.sendOffer = function () {
        return new Promise((resolve, reject) => {

            conn.createOffer(self.options.offerOptions).then(offer => {

                conn.setLocalDescription(offer).then(() => {

                    self.encrypt(JSON.stringify(offer), pubkey).then(encrypted => {
                        
                        context.channel.offer(uid, encrypted, self.options.encodeMedia).then(response => {
                            resolve(response);

                        }).catch(reject);
                    })
                }).catch(reject);

            }).catch(reject);
        });
    };

    this.sendAnswer = function (offer) {
        return new Promise((resolve, reject) => {

            self.decrypt(offer).then(decrypted => {

                conn.setRemoteDescription(JSON.parse(decrypted)).then(() => {

                    conn.createAnswer(self.options.offerOptions).then(answer => {

                        conn.setLocalDescription(answer).then(() => {

                            context.channel.answer(uid, answer, self.options.encodeMedia).then(response => {
                                resolve(response);

                            }).catch(reject);

                        }).catch(reject);

                    }).catch(reject);

                }).catch(reject);

            }).catch(reject);
        });
    };

    this.setAnswer = function (answer) {
        return new Promise((resolve, reject) => {

            if ( answer ) {
                conn.setRemoteDescription(answer).then(resolve).catch(reject);
            }
        });
    };

    this.setIce = function (ice) {
        return new Promise((resolve, reject) => {

            if ( ice ) {

                self.decrypt(ice).then(decrypted => {

                    if ( decrypted && decrypted !== null && decrypted !== "null" ) {
                        conn.addIceCandidate(JSON.parse(decrypted)).then(resolve).catch(reject);
                    }
                }).catch(reject);
            } else {
                reject();
            }
        });
    };

    return this;
};

export const RTCContext =  function (options) {
    var self = this,
        peers = {};

    this.channel = null;
    this.localStream = null;
    this.screenStream = null;
    this.iceServers = {};
    this.audioDetection = null;

    this.options = { ...{

        // Debug callback
        debug: function (e) { console.log(e); },

        // Audio detection
        audioDetectionThreshold: 0.08,
        
        peerOptions: {
            encodeMedia: false,
            onAudioDetected: function (event) {},
            onAudioVolume: function (event) {},
            onMediaStream: function (event) {},
            onClose: function (event) {},
            onIceConnectionStateChange: function (event) {}
        },

        // Context options
        onJoin: function (event) {},
        onLeave: function (event) {},
        onMedia: function (event) {},
        onScreenSharing: function (event) {},
        onScreenSharingEnded: function (event) {},
        onAudioDetected: function (event) {},
        onAudioVolume: function (event) {}
    }, ...options};

    this.startVideoBroadcast = function () {

        for ( var uid in peers ) {
            peers[uid].startVideoBroadcast();
        }
    };

    this.stopVideoBroadcast = function () {

        for ( var uid in peers ) {
            peers[uid].stopVideoBroadcast();
        }
    };

    this.onJoin = function (data) {
        console.log("JOIN", data);
        
        if ( data.uid ) {

            (function (uid, pubkey, encode) {
                encode = ( encode !== undefined && encode === true && RTCRtpSender.prototype.createEncodedStreams !== undefined ) ? true : false;
                
                new RTCConnection(self, data.uid, data.pubkey, {...self.options.peerOptions, ...{'encodeMedia': encode}}).create(self.localStream).then(conn => {
                    peers[data.uid] = conn;

                    conn.sendOffer().then().catch(e => {

                        if ( self.options.debug && typeof self.options.debug === 'function' ) {
                            self.options.debug.apply(self, [e]);
                        }
                    });
                }).catch(e => {

                    if ( self.options.debug && typeof self.options.debug === 'function' ) {
                        self.options.debug.apply(self, [e]);
                    }
                });

                if ( self.options.onJoin && typeof self.options.onJoin === 'function' ) {
                    let response = {uid: data.uid, video: data.video, audio: data.audio};

                    if ( data.name ) {
                        response.name = data.name;
                    }

                    self.options.onJoin.apply(self, [data]);
                }

            })(data.uid, data.pubkey, data.encode);
        }
    };

    this.onLeave = function (data) {

        if ( data.uid ) {
            var conn = peers[data.uid]

            if ( conn ) {
                conn.close();
            }

            delete peers[data.uid];

            if ( self.options.onLeave && typeof self.options.onLeave === 'function' ) {
                self.options.onLeave.apply(self, [{uid: data.uid}]);
            }
        }
    };

    this.onOffer = function (data) {

        if ( data.uid && data.offer && data.pubkey ) {

            (function (uid, offer, pubkey, encode) {
                encode = ( encode !== undefined && encode === true && RTCRtpSender.prototype.createEncodedStreams !== undefined ) ? true : false;

                if ( peers[uid] === undefined ) {
                    new RTCConnection(self, uid, pubkey, {...self.options.peerOptions, ...{'encodeMedia': encode}}).create().then(conn => {
                        peers[uid] = conn;

                        conn.sendAnswer(offer).then().catch(e => {

                            if ( self.options.debug && typeof self.options.debug === 'function' ) {
                                self.options.debug.apply(self, [e]);
                            }
                        });

                    }).catch(e => {

                        if ( self.options.debug && typeof self.options.debug === 'function' ) {
                            self.options.debug.apply(self, [e]);
                        }
                    });
                }

            })(data.uid, data.offer, data.pubkey, data.encode);
        }
    };

    this.onAnswer = function (data) {

        if ( data.uid && peers[data.uid] !== undefined ) {
            var conn = peers[data.uid];

            if ( conn ) {
                conn.setAnswer(data.answer).then().catch(e => {

                    if ( self.options.debug && typeof self.options.debug === 'function' ) {
                        self.options.debug.apply(self, [e]);
                    }
                });
            }
        }
    };

    this.onICE = function (data) {

        if ( data.uid && data.ice ) {

            if ( peers[data.uid] !== undefined ) {
                var conn = peers[data.uid];

                if ( conn ) {
                    conn.setIce(data.ice).then().catch(e => {

                        if ( self.options.debug && typeof self.options.debug === 'function' ) {
                            self.options.debug.apply(self, [e]);
                        }
                    });
                }
            }
        }
    };

    this.onAudioDetected = function (event) {

        self.channel.audio(true).then().catch(e => {

            if ( self.options.debug && typeof self.options.debug === 'function' ) {
                self.options.debug.apply(self, [e]);
            }
        });

        if ( self.options.onAudioDetected && typeof self.options.onAudioDetected === 'function' ) {
            self.options.onAudioDetected.apply(self, [event]);
        }
    };

    this.toggleScreenSharing = function () {
        return new Promise((resolve, reject) => {

            if ( self.screenStream === null ) {
                navigator.mediaDevices.getDisplayMedia({video:true}).then(stream => {
                    self.screenStream = stream;

                    self.screenStream.getVideoTracks()[0].onended = function (event) {
                        self.screenStream = null;

                        for ( let uid in peers ) {
                            peers[uid].changeVideoTrack(self.localStream.getVideoTracks()[0]);
                        }

                        if ( self.options.onScreenSharingEnded && typeof self.options.onScreenSharingEnded === 'function' ) {
                            self.options.onScreenSharingEnded.apply(self, [event]);
                        }
                    };

                    for ( let uid in peers ) {
                        peers[uid].changeVideoTrack(stream.getVideoTracks()[0]);
                    }

                    resolve(stream);

                }).catch(e => {

                    if ( self.options.debug && typeof self.options.debug === 'function' ) {
                        self.options.debug.apply(self, [e]);
                    }

                    reject();
                });

            } else {
                self.screenStream.getVideoTracks()[0].stop();
                self.screenStream = null;

                for ( let uid in peers ) {
                    peers[uid].changeVideoTrack(self.localStream.getVideoTracks()[0]);
                }

                resolve(null);
            }
        });
    };

    this.createMediaStream = function (video, audio) {
        return new Promise((resolve, reject) => {

            if ( navigator.mediaDevices ) {
                video = ( video !== undefined && video == true ) ? true : false;
                audio = ( audio !== undefined && audio == true ) ? {echoCancellation: true} : false;

                if ( audio == true || video == true ) {

                    navigator.mediaDevices.getUserMedia({
                        audio: audio,
                        video: video
                    }).then(stream => {
                        this.localStream = stream;

                        if ( stream.getAudioTracks().length == 1 ) {
                            var audioStream = new MediaStream();
                            audioStream.addTrack(stream.getAudioTracks()[0]);

                            self.audioDetection = new AudioDetection(self.options.audioDetectionThreshold).connect(
                                audioStream,
                                self.onAudioDetected,
                                self.options.onAudioVolume
                            );
                        }

                        resolve(stream);

                    }).catch(reject);

                } else {
                    reject();
                }

            } else {
                reject()
            }
        });
    };

    this.getConnections = function () {
        return peers;
    };

    this.onVideoEvent = function (data) {

        if ( data.uid && data.active !== undefined ) {
            let target = peers[data.uid];

            if ( target ) {

                if ( data.active === true ) {
                    target.startVideoBroadcast();

                } else {
                    target.stopVideoBroadcast();
                }
            }
        }
    };

    this.onMedia = function( event ) {

        if ( self.options.onMedia && typeof self.options.onMedia === 'function' ) {
            self.options.onMedia.apply(self, [event]);
        }
    };

    this.connect = function (url, uid) {
        return new Promise((resolve, reject) => {

            if ( url ) {
                var channel = new SignalingChannel();

                if ( channel ) {
                    channel.create(url).then(() => {
                        channel.addEventListener('join', self.onJoin);
                        channel.addEventListener('leave', self.onLeave);
                        channel.addEventListener('offer', self.onOffer);
                        channel.addEventListener('answer', self.onAnswer);
                        channel.addEventListener('ice', self.onICE);
                        channel.addEventListener('video', self.onVideoEvent);
                        channel.addEventListener('media', self.onMedia);

                        channel.getIceServers().then(response => {
                            this.iceServers = response.result;
                            self.channel = channel;

                            resolve(channel);
                        });

                    }).catch(reject);

                } else {
                    reject();
                }

            } else {
                reject();
            }
        });
    };

    this.createEmptyVideoStream = function (width, height) {
        width = ( width === undefined ) ? 640 : width;
        height = ( height === undefined ) ? 480 : height;

        let canvas = Object.assign(document.createElement("canvas"), {width, height});
        canvas.getContext('2d').fillRect(0, 0, width, height);

        let stream = canvas.captureStream();

        let track = Object.assign(stream.getVideoTracks()[0], {enabled: false});

        return new MediaStream([track]);
    };

    this.emptyVideoStream = self.createEmptyVideoStream();

    return this;
};

export default { RTCContext, RTCPeerConnection };
