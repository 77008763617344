<template>
    <div class="c-local-video" ref="container">
        <video ref="local"></video>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            stream: state => state.local.stream
        })
    },
    methods: {
        resize() {

            const tracks = this.stream.getTracks();
            let width, height;

            tracks.forEach( (track) => {
                const settings = track.getSettings();

                if ( settings.width && settings.height ) {
                    width = settings.width;
                    height = settings.height;
                }

            });

            if ( window.innerHeight > window.innerWidth && width > height ) {

                let cwidth = width;
                let cheight = height;

                width = cheight;
                height = cwidth;
            }

            //portet
            if ( width < height ) {

                if ( height > 150 ) {
                    width = width * ( 150 / height );
                }
            }
            //landscape
            else {
                if ( width > 200 ) {
                    width = 200
                }
            }

            this.$refs.local.width = width;
        }
    },
    mounted() {
        this.$refs.local.srcObject = this.stream;
        this.$refs.local.muted = true;

        this.$refs.local.onloadedmetadata = () => {
            this.resize();

            this.$refs.local.muted = true;
            this.$refs.local.classList.add('loaded');
            this.$refs.local.play();
        };

        window.addEventListener('orientationchange', () => {
            this.resize();
        });
    }
}
</script>
