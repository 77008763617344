import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import DefaultLayout from '@layouts/Default.vue';

// Views
import Start from '@views/Start.vue';
import Preview from '@views/Preview.vue';
import Room from '@views/Room.vue';

const router = new VueRouter({
	mode: 'history',
	routes: [{
		component: DefaultLayout,
		path: '/',
		children: [{
			path: '',
	        name: 'start',
			component: Start
		}]
	},
	{
		component: DefaultLayout,
		path: '/room',
		children: [{
			path: '',
	        name: 'room',
			component: Room
		}]
	},
	{
		component: DefaultLayout,
		path: '/preview',
		children: [{
			path: '',
	        name: 'preview',
			component: Preview
		}]
	},]
});

export default router;
