<template>
    <div class="c-actionbar">
        <div class="c-actionbar__content">
            <div class="c-actionbar__left">
                <button type="button" class="c-btn--unstyled" @click="toggleFullScreen"><i :class="[fullscreen ? 'icon-shrink' : 'icon-enlarge']"></i></button>
            </div>
            <div class="c-actionbar__center">
                <ul class="list-inline">
                    <li>
                        <button type="button" class="c-btn c-btn--round c-btn--hollow icon-mic" @click="toggle('audio')" :class="{'c-btn--tertiary icon-mic-disabled': audio.off}"></button>
                    </li>
                    <li>
                        <button type="button" class="c-btn c-btn--round c-btn--hollow icon-phone c-btn--tertiary" @click="$emit('leave')"></button>
                    </li>
                    <li>
                        <button type="button" class="c-btn c-btn--round c-btn--hollow icon-camera" @click="toggle('video')" :class="{'c-btn--tertiary icon-camera-disabled': video.off}"></button>
                    </li>
                </ul>
            </div>
            <div class="c-actionbar__right" v-if="share">
                <button type="button" class="c-btn--unstyled" v-if="!isSharing" @click="$emit('share')"><i class="icon-present"></i>Scherm delen</button>
                <button type="button" class="c-btn--unstyled" v-else @click="$emit('endSharing')"><i class="icon-unpresent"></i>Delen afsluiten</button>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    props: {
        isSharing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            share: false,
            fullscreen: false
        }
    },

    methods: {
        toggle(type) {

            switch (type) {
                case 'audio':
                        this.$store.dispatch('local/toggleAudio', !this.audio.off);
                    break;
                case 'video':
                        this.$store.dispatch('local/toggleVideo', !this.video.off);
                    break;
            };
        },
        toggleFullScreen() {

            const elem = document.documentElement;
            this.fullscreen = !this.fullscreen;

            if ( this.isFullscreen() ) {

                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            }
            else {

                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            }
        },
        isFullscreen() {
            return ((document.fullscreen) || (window.innerWidth == screen.width && window.innerHeight == screen.height));
        }
    },

    computed: {

        isMobile() {
            return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) );
        },

        ...mapState({
            audio: state => state.local.audio,
            video: state => state.local.video,
        })
    },
    mounted() {
        this.share = !this.isMobile;
        this.fullscreen = this.isFullscreen();

        window.addEventListener("resize", (event)=> {
            this.fullscreen = this.isFullscreen();
        }, false);
    }
}
</script>
