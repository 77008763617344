var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "o-view o-view--center" }, [
    _vm.view == "name"
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.next($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "c-form-element",
                class: { error: _vm.error.name }
              },
              [
                _c("div", { staticClass: "c-form-element__input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Je (bij)naam" },
                    domProps: { value: _vm.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.name = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm._m(0)
              ]
            )
          ]
        )
      : _vm.view == "room"
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.start($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "c-form-element",
                class: { error: _vm.error.room }
              },
              [
                _c("div", { staticClass: "c-form-element__input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.room,
                        expression: "room"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Gespreksruimte" },
                    domProps: { value: _vm.room },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.room = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm._m(1)
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-form-element__action" }, [
      _c(
        "button",
        { staticClass: "c-btn c-btn--primary", attrs: { type: "submit" } },
        [_vm._v("Volgende")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-form-element__action" }, [
      _c(
        "button",
        { staticClass: "c-btn c-btn--primary", attrs: { type: "submit" } },
        [_vm._v("Deelnemen")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }