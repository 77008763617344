<template>
    <div class="o-view o-view--center">

        <form @submit.prevent="next" v-if="view == 'name'">
            <div class="c-form-element" :class="{'error': error.name}">
                <div class="c-form-element__input">
                    <input type="text" v-model="name" placeholder="Je (bij)naam" />
                </div>
                <div class="c-form-element__action">
                    <button type="submit" class="c-btn c-btn--primary">Volgende</button>
                </div>
            </div>
        </form>

        <form @submit.prevent="start" v-else-if="view == 'room'">
            <div class="c-form-element" :class="{'error': error.room}">
                <div class="c-form-element__input">
                    <input type="text" v-model="room" placeholder="Gespreksruimte" />
                </div>
                <div class="c-form-element__action">
                    <button type="submit" class="c-btn c-btn--primary">Deelnemen</button>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data () {
        return {
            view: 'name',
            error:{
                name: false,
                room: false,
            }
        }
    },
    computed: {
        room: {
            set(room) {
                this.error.room = false;
                this.$store.commit('context/setRoom', room);
            },
            get() {
                return this.$store.getters['context/getRoom'];
            }
        },
        name: {
            set(name) {
                this.error.name = false;
                this.$store.commit('context/setName', name);
            },
            get() {
                return this.$store.getters['context/getName'];
            }
        },
        ...mapState({
            stream: state => state.local.stream
        })
    },
    methods: {
        next() {
            if ( this.name && this.name !== "" && this.name.trim().length > 3 ) {
                this.name = this.name.trim().toLowerCase();
                this.view = 'room';
            }
            else {
                this.error.name = true;
            }
        },
        start() {
            if ( this.room && this.room !== "" && this.room.trim().length > 3 ) {
                this.room = this.room.trim().toLowerCase();
                this.$router.push({name: 'preview'})
            }
            else {
                this.error.room = true;
            }
        }
    },
    mounted() {
        if ( this.stream ) {
            this.stream.getTracks().forEach(track => track.stop());
        }
    }
 }

</script>
