export default new (function () {
    var self = this;

    this.processArray = function (array, fn) {
       var results = [];

       return array.reduce(function(p, item) {
           return p.then(function() {
               return fn(item).then(function(data) {
                   results.push(data);
                   return results;
               });
           });
       }, Promise.resolve());
    };

    this.sha256 = function (str) {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(str)).then(buffer => {
                var array = Array.from(new Uint8Array(buffer));

                if ( array ) {
                    resolve(array.map(b => b.toString(16).padStart(2, '0')).join(''));
                } else {
                    reject();
                }
            }).catch(reject);
        });
    };

    this.btoa = function (data) {
        return btoa(String.fromCharCode.apply(null, data));
    };

    this.atob = function (encoded) {
        return new Uint8Array(atob(encoded).split("").map(function(c) { return c.charCodeAt(0); }));
    };

    this.strToBytes = function (byteStr) {
        var bytes = new Uint8Array(byteStr.length)

        for ( var i = 0; i < byteStr.length; i++ ) {
            bytes[i] = byteStr.charCodeAt(i)
        }

        return bytes;
    };

    this.bufferToBase64 = function (buffer) {

        if ( buffer ) {
            var binstr = Array.prototype.map.call(buffer, function (ch) {
                return String.fromCharCode(ch);
            }).join('');

            return btoa(binstr);
        }

        return null;
    };

    this.ab2str = function (buf) {
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    };

    this.unique = function (length) {
        var uid = "",
            possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        length = ( length === undefined ) ? 10 : length;

        for( var i=0; i < length; i++ ) {
            uid += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return uid;
    };
})();
