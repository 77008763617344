import { RSA_OAEP, RSA_OAEP_AES256_CBC } from '@core/lib/Cipher';
import Utils from '@core/lib/Utils';

export default function () {
    var self = this,
        socket,
        queue = {},
        listeners = {};

    this.psk = null;
    this.psk_pem = null;
    this.rsakeys = null

    this.onClose = function (event) {
        console.log(event);
    };

    this.onError = function (event) {
        console.log(event);
    };

    this.onMessage = function (event) {

        if ( event.data ) {

            (function (e) {

                RSA_OAEP_AES256_CBC.decrypt(Utils.atob(e.data), self.rsakeys.private).then(result => {

                    if ( result ) {
                        var response = JSON.parse(new TextDecoder().decode(result));

                        if ( response ) {

                            if ( response.uid && queue[response.uid] !== undefined ) {

                                if ( response.status === 200 ) {
                                    queue[response.uid]['resolve'].apply(this, [response]);

                                } else {
                                    queue[response.uid]['reject'].apply(this, [response]);
                                }

                                delete queue[response.uid];

                            } else if ( response.event ) {

                                if ( listeners[response.event] !== undefined && listeners[response.event] ) {

                                    for ( var listener of listeners[response.event] ) {

                                        if ( typeof listener == "function" ) {
                                            listener.apply(this, [( response.result !== undefined ) ? response.result : undefined]);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }).catch(e => {
                    console.log(e);
                });

            })(event);
        }
    };

    this.addEventListener = function (event, callback) {

        if ( listeners[event] === undefined ) {
            listeners[event] = [];
        }

        listeners[event].push(callback);
    };

    this.removeEventListener = function (event, callback) {

        if ( listeners[event] !== undefined ) {
            var index = listeners[event].indexOf(callback);

            if ( index != -1 ) {
                delete listeners[event][index];
            }
        }
    };

    this.call = function (method, params) {
        return new Promise((resolve, reject) => {
            params = ( params === undefined ) ? null : params;

            if ( method ) {
                var uid = Utils.unique(32);

                if ( uid ) {
                    var packet = {
                        'uid': uid,
                        'method': method,
                        'params': params
                    };

                    RSA_OAEP_AES256_CBC.encrypt(JSON.stringify(packet), self.psk).then(request => {
                        queue[uid] = {
                            'resolve': resolve,
                            'reject': reject
                        };

                        socket.send(Utils.btoa(request));
                    }).catch(reject);
                }

            } else {
                reject();
            }
        });
    };

    this.create = function (url) {
        return new Promise((resolve, reject) => {

            if ( url ) {
                socket = new WebSocket(url);

                socket.onmessage = function (event) {

                    if ( event.data ) {
                        self.psk_pem = event.data;

                        RSA_OAEP.importPublicKeyPEM(event.data).then(psk => {

                            if ( psk ) {
                                RSA_OAEP.genkeys().then(keys => {

                                    RSA_OAEP.exportPublicKey(keys.public).then(exported => {
                                        var packet = JSON.stringify({
                                            'method': 'Proto:pubkey',
                                            'params': {
                                                'key': exported
                                            }
                                        });

                                        RSA_OAEP_AES256_CBC.encrypt(packet, psk).then(result => {
                                            self.psk = psk;
                                            self.rsakeys = keys;

                                            socket.onmessage = function (event) {

                                                if ( event.data ) {

                                                    RSA_OAEP_AES256_CBC.decrypt(Utils.atob(event.data), self.rsakeys.private).then(result => {

                                                        if ( result ) {
                                                            var response = JSON.parse(new TextDecoder().decode(result));

                                                            if ( response && response.status && response.status == 200 ) {
                                                                socket.onmessage = self.onMessage;
                                                                socket.onerror = self.onError;
                                                                socket.onclose = self.onClose;

                                                                resolve(self);

                                                            } else {
                                                                socket.close(1000, "Connection aborted.");

                                                                reject();
                                                            }
                                                        } else {
                                                            reject();
                                                        }
                                                    }).catch(reject);
                                                }
                                            };

                                            socket.send(Utils.btoa(result));
                                        });
                                    });
                                });
                            }
                        }).catch(reject);

                    } else {
                        reject();
                    }
                };

                socket.onerror = function (event) {
                    reject(event);
                };

                socket.onclose = function (event) {
                    reject(event);
                };

            } else {
                reject();
            }
        });
    };

    return this;
};
