var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-video", class: { "c-video--portrait": _vm.portrait } },
    [
      _vm.debug
        ? _c("div", { staticClass: "c-video__debug" }, [
            _vm._v(
              _vm._s(_vm.connection.uid) +
                " - " +
                _vm._s(_vm.connection.audio_end)
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.placeholder,
            expression: "!placeholder"
          }
        ],
        ref: "video"
      }),
      _vm._v(" "),
      _vm.placeholder
        ? _c("div", { staticClass: "c-video__placeholder" }, [
            _vm.connection.name
              ? _c("div", { staticClass: "c-video__initials" }, [
                  _vm._v(_vm._s(_vm.initials))
                ])
              : _c("div", { staticClass: "c-video__icon" }, [
                  _c("img", {
                    attrs: {
                      src: "/res/assets/dist/img/svg/camera-disabled-white.svg",
                      height: "72"
                    }
                  })
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.connection.audio
        ? _c("div", { staticClass: "c-video__mute" }, [
            _c("i", { staticClass: "icon-mic-disabled" })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }