import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

router.beforeEach((to, from, next) => {

	if ( from.name =='room' ) {
		window.location.href = '/';
	}
	else {
		next();
	}
	
});

const app = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#application');
