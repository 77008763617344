<template>
    <div class="o-view o-view--center c-preview">
        <h1 class="c-preview__title">Kies je audio en video instellingen voor je gesprek.</h1>
        <div class="c-preview__video">
            <video ref="preview"></video>
        </div>
        <div class="c-preview__options">
            <ul class="list-inline">
                <li>
                    <button type="button" class="c-btn c-btn--round c-btn--hollow icon-mic" @click="toggleAudio" :disabled="audio.disabled" :class="{'c-btn--tertiary icon-mic-disabled': audio.off}"></button>
                </li>
                <li>
                    <button type="button" class="c-btn c-btn--round c-btn--hollow icon-camera" @click="toggleVideo" :disabled="video.disabled"  :class="{'c-btn--tertiary icon-camera-disabled': video.off}"></button>
                </li>
            </ul>
        </div>
        <div class="c-preview__action">
            <button type="button" @click="join" class="c-btn c-btn--secondary">Nu deelnemen</button>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    data() {
        return {
            stream: null,
            audio: {
                off: true,
                disabled: true
            },
            video: {
                off: true,
                disabled: true
            }
        }
    },

    methods: {

        join() {
            this.$router.push({name: 'room'});
        },

        toggleAudio() {
            this.audio.off = !this.audio.off;

            const audiotracks = this.stream.getAudioTracks();

            if ( audiotracks && audiotracks.length > 0 ){
                audiotracks[0].enabled = !this.audio.off;
            }

            this.$store.dispatch('local/toggleAudio', this.audio.off);
        },

        toggleVideo() {
            this.video.off = !this.video.off;

            const videotracks = this.stream.getVideoTracks();

            if ( videotracks && videotracks.length > 0 ){
                videotracks[0].enabled = !this.video.off;
            }

            this.$store.dispatch('local/toggleVideo', this.video.off);
        }
    },

    computed: {
        ...mapState({
            room: state => state.context.room
        })
    },

    mounted() {

        if ( !this.room ) {
            this.$router.push({name:'start'});
        }
        else if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){

            navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user", width: 1280, height: 720 } }).then( ( stream ) => {

                this.stream = stream;
                this.$store.dispatch('local/addStream', this.stream );

                this.audio.off = this.audio.disabled = false;
                this.$store.dispatch('local/toggleAudio', false);

                this.video.off = this.video.disabled = false;
                this.$store.dispatch('local/toggleVideo', false);

                /**
                 * Add stream to video object
                 */
                this.$nextTick( () => {
                    this.$refs.preview.srcObject = stream;

                    this.$refs.preview.onloadedmetadata = () => {
                        this.$refs.preview.play();
                        this.$refs.preview.muted = true;
                    };
                });

            }, () => {

                this.audio.off = true;
                this.audio.disabled = true;
                this.video.off = true;
                this.video.disabled = true;

            });
        }
    }
}
</script>
