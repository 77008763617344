import SecureSocket from '@core/lib/Socket';

export default function () {
    var self = this,
        room = null,
        socket = null;

    this.getKeys = function () {
        return socket.rsakeys;
    };

    this.getIceServers = function () {
        return new Promise((resolve, reject) => {

            if ( socket ) {

                socket.call('RTC:iceServers').then(response => {
                    resolve(response);
                }).catch(reject);
            } else {
                reject();
            }
        });
    };

    this.answer = function (uid, answer, encode) {
        return new Promise((resolve, reject) => {
            encode = ( encode !== undefined && encode === true ) ? true : false;

            if ( socket ) {

                if ( uid && answer ) {
                    socket.call('RTC:answer', {'uid': uid, 'answer': answer, 'encode': encode}).then(response => {
                        resolve(response);
                    }).catch(reject);
                } else {
                    reject();
                }
            } else {
                reject();
            }
        });
    };

    this.offer = function (uid, offer, encode) {
        return new Promise((resolve, reject) => {
            encode = ( encode !== undefined && encode === true ) ? true : false;
            
            // Hardcoded false, disable media encoding for now
            encode = false;
            
            if ( socket ) {

                if ( uid && offer ) {
                    socket.call('RTC:offer', {'uid': uid, 'offer': offer, 'encode': encode}).then(response => {
                        resolve(response);
                    }).catch(reject);
                } else {
                    reject();
                }
            } else {
                reject();
            }
        });
    };

    this.join = function (uid, video, audio, name, encode) {
        return new Promise((resolve, reject) => {

            if ( socket && uid ) {
                
                if ( encode === undefined || encode === null ) {
                    encode = ( RTCRtpSender.prototype.createEncodedStreams !== undefined ) ? true : false;
                }
                
                // Hardcoded false, disable media encoding for now
                encode = false;
                
                socket.call('RTC:join', {'uid': uid, 'video': video, 'audio': audio, 'name': name, 'encode': encode}).then(response => {
                    resolve(response);
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.ice = function (uid, ice) {
        return new Promise((resolve, reject) => {

            if ( socket && ice ) {
                socket.call('RTC:ice', {'uid': uid, 'ice': ice}).then(response => {
                    resolve(response);
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.leave = function (uid) {
        return new Promise((resolve, reject) => {

            if ( socket && uid ) {
                socket.call('RTC:leave', {'uid': uid}).then(response => {
                    resolve(response);
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.audio = function (active) {
        return new Promise((resolve, reject) => {
            active = ( active !== undefined && active === true ) ? true : false;

            if ( socket ) {
                socket.call('RTC:audio', {'active': active}).then(response => {
                    resolve(response);
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.video = function (uid, active) {
        return new Promise((resolve, reject) => {
            active = ( active !== undefined && active === true ) ? true : false;

            if ( socket && uid ) {
                socket.call('RTC:video', {'uid': uid, 'active': active}).then(response => {
                    resolve(response);
                }).catch(reject);

            } else {
                reject();
            }
        });
    };

    this.media = function (video, audio) {

        if ( socket ) {

            if ( typeof video == "boolean" && typeof audio == "boolean" ) {
                socket.call('RTC:media', {'video': video, 'audio': audio}).then(response => {}).catch( () => {});
            }
        }
    };

    this.create = function(url) {
        return new Promise((resolve, reject) => {
            new SecureSocket().create(url).then(channel => {
                socket = channel;

                if ( channel ) {
                    resolve(self);
                }
            }).catch(reject);
        });
    };

    this.addEventListener = function (event, callback) {
        socket.addEventListener(event, callback);
    };

    this.removeEventListener = function (event, callback) {
        socket.removeEventListener(event, callback);
    };

    return this;
};
